// src/components/Dashboard/Dashboard.js
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Container, LoadingOverlay, Divider } from '@mantine/core';
import { useAppContext } from '../../context/AppContext';
import CommentModal from './CommentModal';
import CategoryAccordion from './CategoryAccordion';
import useRealtimeUpdates from '../../hooks/useRealtimeUpdates';
import pb from "../../pocketbaseClient";
import submitComment from '../submitComment'; // Adjust the import path as necessary
import LoadingIndicator from '../LoadingIndicator'; // Assuming you have a LoadingIndicator component
import ErrorBoundary from '../ErrorBoundary';


const Dashboard = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { digestItems, setDigestItems } = useAppContext();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [opened, setOpened] = useState(false);
  const [currentComment, setCurrentComment] = useState('');
  const [currentItemId, setCurrentItemId] = useState(null);
  const categories = ["الأولويات", "ملخص", "المواضيع المعلقة", "تحديثات المشاريع"];
  const [activeAccordionValue, setActiveAccordionValue] = useState(categories.map((_, index) => `panel-${index}`)); // For active items, start expanded
  const [archivedAccordionValue, setArchivedAccordionValue] = useState([]); // For archived items, start collapsed
  const userid = user.id;
  const [isLoading, setIsLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [isSubmittingComment, setIsSubmittingComment] = useState(false);

  useRealtimeUpdates(); // Custom hook for handling real-time updates
console.log(user)
  useEffect(() => {
    if (!user) {
      navigate("/login");
    } else {
      // Check if the user is the CEO when the dashboard loads
      if (user.role === 'CEO' || user.id === 'ceo_user_id') {  // Adjust condition according to your auth setup
        logCEOVisit(user);
      }
    }
  }, [user, navigate]);

  const logCEOVisit = async (user) => {
    try {
      // Assuming you have a collection to log visits or actions
      await pb.collection("Logs").create({
        userId: user.id,
        action: "Visited Dashboard",
        timestamp: new Date()
      });
      console.log("Logged CEO dashboard visit.");
    } catch (error) {
      console.error("Error logging CEO visit:", error);
    }
  };


  useEffect(() => {
    let isCancelled = false;
  
    const loadDigestItems = async () => {
      setLoading(true);
      try {
        const records = await pb.collection("DigestItems").getFullList({
          expand: "Comments_via_DigestItemId.Author",

        });
        if (!isCancelled) {
          setDigestItems(records);
          setIsLoading(false);
        }
      } catch (error) {
        if (!isCancelled) {
          console.error("Error fetching digest items:", error);
          setError(error.message);
        }
      } finally {
        setLoading(false);
      }
    };
  
    loadDigestItems();
  
    return () => {
      isCancelled = true;  // Set this to true to ignore the response if the component unmounts
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

  const handleOpenModal = (itemId) => {
    setCurrentItemId(itemId);
    setOpened(true);
  };

  const handleCommentSubmit = async () => {
    setIsSubmittingComment(true); // Start loading
    try {
      await submitComment({
        userid,
        currentItemId,
        currentComment,
        setCurrentComment,
        setOpened,
      });
      // Optionally reset the comment state here if not done inside submitComment
      setCurrentComment(""); // Clear comment field
      setOpened(false); // Close the modal
    } catch (error) {
      console.error("Failed to submit comment:", error);
      // Handle error (e.g., show a notification to the user)
    } finally {
      setIsSubmittingComment(false); // End loading
    }
  };

  // Filtering items for the active and archived sections based on the "closed" boolean field
  const activeItems = digestItems.filter(item => !item.closed);
  const archivedItems = digestItems.filter(item => item.closed);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (isLoading) {
    return <LoadingIndicator />; // Show a loading indicator while the auth state is being fetched
  }
  return (
      <ErrorBoundary> {/* Wrap your application in an ErrorBoundary to catch and handle errors gracefully */}

      <Container fluid mt={20}>
      <LoadingOverlay visible={loading} />
      <CommentModal
        opened={opened}
        setOpened={setOpened}
        currentComment={currentComment}
        setCurrentComment={setCurrentComment}
        handleSubmitComment={handleCommentSubmit}
      />
      {/* Active Items Section */}
      <CategoryAccordion
        categories={categories}
        digestItems={activeItems}
        setUpdateTrigger={() => {}}
        handleOpenModal={handleOpenModal}
        value={activeAccordionValue}
        setValue={setActiveAccordionValue}
      />

      {/* Divider for visual separation */}
      <Divider my="lg" label="Archived Items" labelPosition="center" />

      {/* Archived Items Section */}
      <CategoryAccordion
        categories={categories}
        digestItems={archivedItems}
        setUpdateTrigger={() => {}}
        handleOpenModal={handleOpenModal}
        value={archivedAccordionValue} // Use a separate state to keep these collapsed by default
        setValue={setArchivedAccordionValue}
      />
    </Container>
        </ErrorBoundary>

        );
};

export default Dashboard;
