import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import Dashboard from './components/Dashboard/Dashboard';
import Login from './components/Login';
import Header from './components/Header'; // Import the Header component
import AddItemForm from './components/AddItemForm'; // Make sure this is the correct import path

function App() {
  const [formOpened, setFormOpened] = useState(false);

  return (
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={
              <ProtectedRoute>
                <Header setFormOpened={setFormOpened} /> {/* Use the Header component */}
                <Dashboard />
              </ProtectedRoute>
            } />
            {/* Add more routes as needed */}
            {/* Ensure AddItemForm is invoked correctly and is passed the necessary props */}
          </Routes>
          <AddItemForm opened={formOpened} setOpened={setFormOpened} />

        </Router>
  );
}

export default App;