// src/components/Dashboard/CommentModal.js
import React, { useState } from 'react';
import { Modal, Button, Textarea, Loader } from '@mantine/core';

const CommentModal = ({ opened, setOpened, currentComment, setCurrentComment, handleSubmitComment }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCommentSubmit = async () => {
    setIsSubmitting(true);
    await handleSubmitComment(); // This function should be adapted to handle the loading state
    setIsSubmitting(false);
  };

  return (
      <Modal
          opened={opened}
          onClose={() => setOpened(false)}
          title="إضافة تعليق"
          centered
      >
        <Textarea
            label="تعليقك"
            placeholder="اكتب تعليقك هنا"
            value={currentComment}
            onChange={(event) => setCurrentComment(event.currentTarget.value)}
            autosize
            minRows={3}
            maxRows={6}
        />
        <Button mt="md" fullWidth onClick={handleCommentSubmit} disabled={isSubmitting}>
          {isSubmitting ? <Loader size="xs" /> : 'إرسال'}
        </Button>
      </Modal>
  );
};

export default CommentModal;