// src/components/ResponseButton.js
import React, { useState } from 'react';
import { Button, Loader } from '@mantine/core'; // Import Loader from Mantine
import pb from '../pocketbaseClient'; // Ensure this path matches your project structure
import { showNotification } from '@mantine/notifications'; // If you're using Mantine notifications

const ResponseButton = ({ recordId, responseType, children, icon, color, style, onUpdate }) => {
  const [loading, setLoading] = useState(false);

  const updateDigestItemResponse = async () => {
    setLoading(true);

    const data = {
      Status: "تم الرد",
      Response: responseType,
      closed: true,
    };

    try {
      await pb.collection('DigestItems').update(recordId, data);
      onUpdate(); // Trigger any external update mechanisms
    } catch (error) {
      console.error('Error updating record:', error);
      showNotification({ // This assumes you're using Mantine's notification system or similar
        title: 'Error',
        message: 'Failed to update the item. Please try again.',
        color: 'red',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
      <Button
          loading={loading}
          rightSection={loading ? <Loader size="xs" color="white" /> : icon} // Use Loader when loading
          color={color}
          onClick={updateDigestItemResponse}
          style={style}
          disabled={loading} // Disable button when loading
          aria-label={`Set response to ${responseType}`} // Improve accessibility
      >
        {children}
      </Button>
  );
};

export default ResponseButton;