// src/context/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import PocketBase from 'pocketbase';

// Initialize PocketBase client
const pb = new PocketBase('https://ahmed.pockethost.io');

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Add this line 

  useEffect(() => {
    // Check if there's a valid auth token on initial load
    if (pb.authStore.isValid) {
      setUser(pb.authStore.model); 
      console.log('isLoading:', isLoading);
      // Set the user data from the authStore
    }
    setIsLoading(false); 
  }, [isLoading]);

  useEffect(() => {
    if (isLoading) { // Check if isLoading is true
      // ... (Your existing token check logic)
      setIsLoading(false); 
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array

  const login = async (username, password) => {
    try {
      const authData = await pb.collection('users').authWithPassword(username, password);
      setUser(authData); // Set the user state with the received auth data
      return true;
    } catch (error) {
      console.error('Login failed:', error);
      return false;
    }
  };

  const logout = () => {
    pb.authStore.clear(); // Clear the authStore to "logout" the user
    setUser(null); // Clear the user state
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
