// src/components/Dashboard/CategoryAccordion.js
import React from 'react';
import { Accordion, Badge, Group, Text,Stack } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import DigestItem from './DigestItem';

const CategoryAccordion = ({ categories, digestItems, setUpdateTrigger, handleOpenModal, value, setValue }) =>
 {
  console.log('Grabbed5',categories)
  console.log('Grabbed6',digestItems)
  

  return (
    <Accordion
      variant="separated"
      radius="md"
      multiple
      value={value}
      onChange={setValue}
      chevron={<IconPlus size={16} />}
    >
      {categories.map((category, index) => (
        <Accordion.Item value={`panel-${index}`} key={category}>
          <Accordion.Control>
            <Group position="apart">
              <Text weight={500} size="lg">
                {category}
              </Text>
              <Badge color="dark" variant="light">
                {digestItems.filter(item => item.Category === category).length}
              </Badge>
            </Group>
          </Accordion.Control>
          <Accordion.Panel>
            <Stack>
            {digestItems
              .filter(item => item.Category === category)
              .map(item => (
                <DigestItem
                  key={item.id}
                  item={item}
                  handleOpenModal={handleOpenModal}
                  setUpdateTrigger={setUpdateTrigger}
                />
              ))}
              </Stack>
          </Accordion.Panel>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

export default CategoryAccordion;
