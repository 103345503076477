import React, {useState} from 'react';
import {ActionIcon, Badge, Collapse, Group, Loader, Paper, Stack, Text, Tooltip} from '@mantine/core';
import {IconChevronDown, IconChevronUp, IconEye, IconX} from '@tabler/icons-react';
import pb from '../pocketbaseClient'; // Ensure this import path is correct

const CommentsList = ({item}) => {
    const [opened, setOpened] = useState(false);
    const [comments, setComments] = useState(item.expand?.Comments_via_DigestItemId || []);
    const [loadingStates, setLoadingStates] = useState({});
    // Function to mark a comment as read
    // Function to mark a comment as read
    const markAsRead = async (commentId) => {
        setLoadingStates(prev => ({...prev, [commentId]: true}));
        try {
            await pb.collection('Comments').update(commentId, {
                ...comments.find(comment => comment.id === commentId),
                Status: 'Read'
            });
            setComments(comments.map(comment => {
                if (comment.id === commentId) {
                    return {...comment, Status: 'Read'};
                }
                return comment;
            }));
        } catch (error) {
            console.error('Failed to update comment:', error);
        } finally {
            setLoadingStates(prev => ({...prev, [commentId]: false}));
        }
    };

// Function to delete a comment
    const deleteComment = async (commentId) => {
        setLoadingStates(prev => ({...prev, [commentId]: true}));
        try {
            await pb.collection('Comments').delete(commentId);
            setComments(comments.filter(comment => comment.id !== commentId));
        } catch (error) {
            console.error('Failed to delete comment:', error);
        } finally {
            setLoadingStates(prev => ({...prev, [commentId]: false}));
        }
    };

    if (comments.length === 0) {
        return null;
    }

    return (
        <div id={`comments-collapse-${item.id}`}>
            <Group position="center" style={{marginBottom: 10, cursor: 'pointer'}} onClick={() => setOpened(o => !o)}>
                <Text weight={500}>التعليقات:</Text>
                {opened ? <IconChevronUp size={20}/> : <IconChevronDown size={20}/>}
            </Group>
            <Collapse in={opened}>
                <Paper withBorder shadow="xs" p="md" radius="md" style={{textAlign: 'right'}}>
                    {comments.map((comment, index) => (
                        <Stack key={index} align="flex-start" spacing="sm" style={{margin: '10px 0'}}>
                            <Badge variant="gradient" gradient={{from: 'blue', to: 'cyan', deg: 90}} size="lg">
                                {comment.expand?.Author?.name || 'Comment'}
                            </Badge>
                            <Text>{comment.Content}</Text>
                            <Group>
                                <Tooltip label="Mark as Read" position="top">
                                    <ActionIcon color="#228B22" onClick={() => markAsRead(comment.id)}
                                                disabled={loadingStates[comment.id]}>
                                        {loadingStates[comment.id] ? <Loader size="xs"/> : <IconEye size={16}/>}
                                    </ActionIcon>
                                </Tooltip>
                                <Tooltip label="Delete" position="top">
                                    <ActionIcon color="#DC143C" onClick={() => deleteComment(comment.id)}
                                                disabled={loadingStates[comment.id]}>
                                        {loadingStates[comment.id] ? <Loader size="xs"/> : <IconX size={16}/>}
                                    </ActionIcon>
                                </Tooltip>
                            </Group>
                        </Stack>
                    ))}
                </Paper>
            </Collapse>
        </div>
    );
};

export default CommentsList;
