// src/components/Header.js
import React from 'react';
import { ActionIcon } from '@mantine/core';
import { IconPlus, IconLogout } from '@tabler/icons-react';
import { useAuth } from '../context/AuthContext'; // Adjust the import path as necessary

const Header = ({ setFormOpened }) => {
  const { user, logout } = useAuth(); // Assuming useAuth provides user and logout
  console.log('User info:',user); // Log the user state for debugging

  const handleLogout = async () => {
    try {
      await logout(); // Perform logout operation
      // Optionally, navigate to the login page or display a success message
    } catch (error) {
      console.error('Logout failed:', error);
      // Optionally, handle logout errors here
    }
  };

  return (
    <div style={{ backgroundColor: '#0f2536', padding: '10px 20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Logo" style={{ width: '140px' }} />
      {user && (
        <div style={{ display: 'flex', gap: '10px' }}>
          <ActionIcon variant="filled" onClick={() => setFormOpened(true)} size="lg" radius="xl" style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', color: 'white' }}>
            <IconPlus size={16} />
          </ActionIcon>
          <ActionIcon variant="filled" onClick={handleLogout} size="lg" radius="xl" style={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', color: 'white' }}>
            <IconLogout size={16} />
          </ActionIcon>
        </div>
      )}
    </div>
  );
};

export default Header;
