import React, { createContext, useContext, useState } from 'react';

// Create a context
const AppContext = createContext();

// Custom hook to use the context
export const useAppContext = () => useContext(AppContext);

// Provider component that wraps your app and makes the context available to all components
export const AppProvider = ({ children }) => {
  const [digestItems, setDigestItems] = useState([]);
  const [comments, setComments] = useState([]); // Added comments state

  // Add other state management logic as needed
  // For example, managing user authentication, application settings, etc.
  // const [user, setUser] = useState(null);
  // const loginUser = (userData) => setUser(userData);
  // const logoutUser = () => setUser(null);

  // The value that will be supplied to all consuming components of this context
  const value = {
    digestItems,
    setDigestItems,
    comments, // Making comments available in the context
    setComments, // Function to update comments
    // Additional state variables and functions can be added here
    // user,
    // loginUser,
    // logoutUser,
  };

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};
