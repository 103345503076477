import pb from "../pocketbaseClient"; // Adjust the import path as necessary

// Assuming submitComment is imported and used in CommentModal.js
const submitComment = async ({
                               userid,
                               currentItemId,
                               currentComment,
                               setCurrentComment,
                               setOpened,
                             }) => {
  console.log(
      "Submitting comment for item ID:",
      currentItemId,
      "Comment:",
      currentComment
  );

  const data = {
    DigestItemId: currentItemId,
    Author: userid,
    Content: currentComment,
    Status: "Unread",
  };

  const parentitem = {
    Status: "تم الرد",
    Response: "Commented",
  };

  try {
    const record = await pb.collection("Comments").create(data);
    await pb.collection("DigestItems").update(currentItemId, parentitem);
    console.log("Comment submitted successfully:", record);

    setCurrentComment("");
    setOpened(false);
  } catch (error) {
    console.error("Error submitting comment:", error);
    // Handle error (e.g., show a notification to the user)
    throw error; // Rethrow the error if you want to handle it in the calling component (e.g., to stop the loading indicator)
  }
};

export default submitComment;