import React from "react";
import {
  Modal,
  Button,
  TextInput,
  Select,
  Textarea,
  FileInput,
  Paper,
  Group,
  Stack,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import pb from "../pocketbaseClient";
import { DateInput } from "@mantine/dates";

const AddItemForm = ({ opened, setOpened }) => {

  const form = useForm({
    initialValues: {
      Title: "",
      Description: "",
      RequiredDecision: "",
      Category: "الأولويات", // Default category
      ResponseType: "",
      Status: "قيد التنفيذ",
      Priority: "",
      DueDate: null, // Start with empty date
      attachment: [],
    },

    validate: {
      Title: (value) =>
        value.length > 2 ? null : "Title must be at least 3 characters long",
      Description: (value) =>
        value.length > 5 ? null : "Description must be at least 6 characters",
      // Add validation rules for other fields as needed
    },
  });

  const handleSubmit = async (values) => {
    try {
      const formData = new FormData();
      formData.append("Title", values.Title);
      formData.append("Description", values.Description);
      formData.append("RequiredDecision", values.RequiredDecision);
      formData.append("Category", values.Category);
      formData.append("ResponseType", values.ResponseType);
      formData.append("Status", values.Status);
      formData.append("Priority", values.Priority);
      formData.append(
        "DueDate",
        values.DueDate ? values.DueDate.toISOString() : null
      );

      // Handle attachments
      const attachments = values.attachment;
      if (attachments && attachments.length > 0) {
        for (const attachment of attachments) {
          formData.append("attachment", attachment);
        }
      }

      const record = await pb.collection("DigestItems").create(formData);
      console.log("New item added successfully:", record);
      setOpened(false);

      // Reset the form
      form.reset();

    } catch (error) {
      console.error("Error adding new item:", error);
    }
  };

  return (
    <Modal
      opened={opened}
      onClose={() => setOpened(false)}
      title="إضافة بند جديد"
    >
      <Paper withBorder shadow="xs" p="md" radius="md">
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack>
        <TextInput
          label="العنوان"
          placeholder="العنوان"
          required
          {...form.getInputProps("Title")}
        />

        <Textarea
          label="الوصف"
          placeholder="الوصف"
          required
          {...form.getInputProps("Description")}
        />

        <TextInput
          label="القرار المطلوب"
          placeholder="أدخل القرار المطلوب"
          {...form.getInputProps("RequiredDecision")}
        />
        <Select
          label="الفئة"
          placeholder="حدد"
          required
          data={[
            { value: "الأولويات", label: "الأولويات" },
            { value: "ملخص", label: "ملخص" },
            { value: "المواضيع المعلقة", label: "المواضيع المعلقة" },
            { value: "تحديثات المشاريع", label: "تحديثات المشاريع" },
          ]}
          {...form.getInputProps("Category")}
        />

        <Select
          label="نوع الاستجابة"
          placeholder="حدد"
          required
          data={[
            { value: "Approval", label: "موافقة" },
            { value: "Informational", label: "إخبارية" },
            { value: "Assignment", label: "تعيين مهمة" },
            { value: "Feedback", label: "تغذية راجعة" },
            { value: "Meeting", label: "المواعيد" },
            { value: "Confirmation", label: "التأكيد" },
          ]}
          {...form.getInputProps("ResponseType")}
        />

        {/* ... other form fields ... */}

        <Select
          label="الحالة"
          placeholder="حدد"
          required
          data={[
            { value: "مفتوح", label: "مفتوح" },
            { value: "مغلق", label: "مغلق" },
            { value: "قيد التنفيذ", label: "قيد التنفيذ" },
            { value: "تم الرد", label: "تم الرد" },
          ]}
          {...form.getInputProps("Status")}
        />

        <Select
          label="الأولوية"
          placeholder="حدد"
          required
          data={[
            { value: "عالي الأهمية", label: "عالي الأهمية" },
            { value: "متوسط الأهمية", label: "متوسط الأهمية" },
            { value: "منخفض الأهمية", label: "منخفض الأهمية" },
          ]}
          {...form.getInputProps("Priority")}
        />
        {/* Similar Select components for 'status' and 'priority' */}

        
        <DateInput
          label="الموعد النهائي"
          placeholder="اختر تاريخًا"
          clearable
          {...form.getInputProps("DueDate")}
        />
        <FileInput
        placeholder="قم بالتحميل"
        clearable
          label="مرفق"
          multiple
          maxFileSize={1048576} // 1MB
          {...form.getInputProps("attachment")}
        />
            <Group position="right" mt="md">

        <Button type="submit" mt="md">
          إضافة
        </Button>
        </Group>
        </Stack>
      </form>
      </Paper>
    </Modal>
  );
};

export default AddItemForm;
