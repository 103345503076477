// src/components/Login.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext'; // Ensure this path is correct
import { Paper, TextInput, PasswordInput, Button, Stack, Text, Container } from '@mantine/core';
import { IconLock, IconUser } from '@tabler/icons-react';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { login, user } = useAuth(); // Ensure you have a login method in your AuthContext
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
        navigate('/'); // Redirect to the dashboard
    }
}, [user, navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await login(username, password); // Implement your login logic here
      navigate('/'); // Navigate to the home page after successful login
    } catch (error) {
      console.error('Login failed:', error);
      // Optionally, handle login errors here (e.g., display an error message)
    }
  };

  return (
    <Container size={420} my={40}>
      <Paper radius="md" p="xl" withBorder>
        <Text ta="center" size="lg" weight={500}>
         أهلاً وسهلاً!
        </Text>

        <form onSubmit={handleSubmit}>
          <Stack>
            <TextInput
              icon={<IconUser size={14} />}
              label="أسم المستخدم"
              placeholder="المستخدم"
              required
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />

            <PasswordInput
              icon={<IconLock size={14} />}
              label="كلمة المرور"
              placeholder="كلمة المرور"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              mt="md"
            />

            <Button type="submit" fullWidth mt="xl">
              دخول
            </Button>
          </Stack>
        </form>
      </Paper>
    </Container>
  );
};

export default Login;
