// src/hooks/useRealtimeUpdates.js
import { useEffect } from 'react';
import pb from '../pocketbaseClient';
import { useAppContext } from '../context/AppContext';

const useRealtimeUpdates = () => {
  const { setDigestItems, setComments } = useAppContext();

  useEffect(() => {
    const handleDigestEvent = (e) => {
      console.log('DigestItems event received:', e.action, e.record);
      setDigestItems((prevItems) => {
        switch (e.action) {
          case 'create':
            return [...prevItems, e.record];
          case 'update':
            return prevItems.map((item) => item.id === e.record.id ? { ...item, ...e.record } : item);
          case 'delete':
            return prevItems.filter((item) => item.id !== e.record.id);
          default:
            console.warn(`Unhandled action '${e.action}' in DigestItems`);
            return prevItems;
        }
      });
    };

    const handleCommentEvent = (e) => {
      console.log('Comments event received:', e.action, e.record);
      setComments((prevComments) => {
        switch (e.action) {
          case 'create':
            return [...prevComments, e.record];
          case 'update':
            return prevComments.map((comment) => comment.id === e.record.id ? { ...comment, ...e.record } : comment);
          case 'delete':
            return prevComments.filter((comment) => comment.id !== e.record.id);
          default:
            console.warn(`Unhandled action '${e.action}' in Comments`);
            return prevComments;
        }
      });
    };

    pb.collection('DigestItems').subscribe('*', handleDigestEvent);
    pb.collection('Comments').subscribe('*', handleCommentEvent);

    return () => {
      pb.collection('DigestItems').unsubscribe('*');
      pb.collection('Comments').unsubscribe('*');
    };
  }, [setDigestItems, setComments]); // Ensure the hook is re-run only when these functions change

  console.log('useRealtimeUpdates hook set up');
};

export default useRealtimeUpdates;
