// src/utils/index.js

import { IconCheck, IconX, IconAlertCircle, IconInfoCircle, 
  IconCalendarEvent, IconCrosshair, IconMessage, IconAlertOctagon, IconHelp } from '@tabler/icons-react';

// Utility function to determine the color based on the status of a digest item
export const getStatusColor = (status) => {
  switch (status) {
    case "مفتوح":
      return "#0056b3"; // Vibrant Navy for open items
    case "مغلق":
      return "#228B22"; // Forest Green for closed items
    case "قيد التنفيذ":
      return "#CC5500"; // Burnt Orange for items in progress
    default:
      return "#36454F"; // Charcoal Gray as default
  }
};

// Utility function to get the appropriate icon based on the priority of a digest item
export const getPriorityIcon = (priority) => {
  switch (priority) {
    case "عالي":
      return IconAlertCircle; // Icon for high priority
    case "متوسط":
      return IconInfoCircle; // Icon for medium priority
    case "منخفض":
      return IconCalendarEvent; // Icon for low priority
    default:
      return null; // Return null or a default icon if needed
  }
};

// Utility function to determine the color based on the priority of a digest item
export const getPriorityColor = (priority) => {
  switch (priority) {
    case "عالي الأهمية":
      return "#DC143C"; // Crimson Red for high importance
    case "متوسط الأهمية":
      return "#CC5500"; // Burnt Orange for medium importance
    case "منخفض الأهمية":
      return "#228B22"; // Forest Green for low importance
    default:
      return "#36454F"; // Charcoal Gray as default
  }
};

export const responseTypeArabic = {
  Approval: 'موافقة',
  Informational: 'إخبارية',
  Assignment: 'تعيين مهمة',
  Feedback: 'التغذية الراجعة',
  Meeting: 'الاجتماعات',
  Confirmation: 'التأكيد',
};

export const buttonConfigs = {
  "Approval": [
    { responseType: "إعتماد", icon: <IconCheck size={14} />, color: "#228B22", text: "إعتماد" },
    { responseType: "رفض", icon: <IconX size={14} />, color: "#DC143C", text: "رفض" }
  ],
  "Confirmation": [
    { responseType: "نعم", icon: <IconCheck size={14} />, color: "#228B22", text: "نعم" },
    { responseType: "لا", icon: <IconX size={14} />, color: "#DC143C", text: "لا" } 
  ],
  "Assignment": [
    { responseType: "قبول", icon: <IconCheck size={14} />, color: "#228B22", text: "قبول" },
    { responseType: "رفض", icon: <IconX size={14} />, color: "#DC143C", text: "إعتذار" } 
  ],
  "Feedback": [
    { responseType: "تخطي", icon: <IconX size={14} />, color: "#36454F", text: "تخطي" }
  ],
  "Meeting": [
    { responseType: "حضور", icon: <IconCheck size={14} />, color: "#008080", text: "سأحضر" },
    { responseType: "إعتذار", icon: <IconX size={14} />, color: "#DC143C", text: "اعتذر عن الحضور" }
  ],
  "Informational": [
    { responseType: "تم القراءة", icon: <IconCheck size={14} />, color: "#0056b3", text: "تم القراءة" } 
  ]
};

export const getIconForResponse = (responseType) => {
  switch (responseType) {
    case "Approval": 
      return <IconCheck size={14} />;
    case "Informational": 
      return <IconInfoCircle size={14} />;
    case "Assignment": 
      return <IconCrosshair size={14} />;
    case "Feedback":
      return <IconMessage size={14} />;
    case "Meeting":
      return <IconCalendarEvent size={14} />;
    case "Confirmation":
      return <IconAlertOctagon size={14} />;
    default: 
      return <IconHelp size={14} />;
  }
};

export const getColorForResponse = (responseType) => {
  switch (responseType) {
    case "Approval": 
      return '#228B22'; // Forest Green for approval
    case "Informational":
      return '#0056b3'; // Vibrant Navy for informational
    case "Assignment":
      return '#CC5500'; // Burnt Orange for assignments
    case "Feedback":
      return '#663399'; // Regal Purple for feedback
    case "Meeting":
      return '#008080'; // Deep Teal for meetings
    case "Confirmation":
      return '#DC143C'; // Crimson Red for confirmations
    default: 
      return '#36454F'; // Charcoal Gray as a neutral default
  }
};
