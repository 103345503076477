// src/index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import './global.css';
import { MantineProvider } from '@mantine/core';
import App from './App';
import { AppProvider } from './context/AppContext';
import { AuthProvider } from './context/AuthContext'; // Import the AuthProvider
import { onLCP, onFID, onCLS } from 'web-vitals';

// Log web vitals
onCLS(console.log);
onFID(console.log);
onLCP(console.log);

// Select the root container where the React app will be attached
const container = document.getElementById('root');
// Create a root
const root = createRoot(container);

// Render the React component into the DOM
root.render(
  <React.StrictMode>
    <AuthProvider> {/* Wrap the App component with AuthProvider */}
      <AppProvider>
        <MantineProvider>
          <App />
        </MantineProvider>
      </AppProvider>
    </AuthProvider>
  </React.StrictMode>
);
