// src/components/AttachmentsList.js
import React, { useState } from 'react';
import { Text, Collapse, Group, Paper, Anchor } from '@mantine/core';
import { IconChevronDown, IconChevronUp, IconFile } from '@tabler/icons-react';
import pb from '../pocketbaseClient'; // Adjust the import path as necessary

const AttachmentsList = ({ item }) => {
  const [opened, setOpened] = useState(false);

  if (!item.attachment || item.attachment.length === 0) {
    return null; // Or render some placeholder if you prefer
  }

  return (
    <div id={`attachment-collapse-${item.id}`}>
      <Group position="center" style={{ marginBottom: 10, cursor: 'pointer' }} onClick={() => setOpened(o => !o)}>
        <Text weight={500}>المرفقات:</Text>
        {opened ? <IconChevronUp size={20} /> : <IconChevronDown size={20} />}
      </Group>
      <Collapse in={opened}>
        <Paper withBorder shadow="xs" p="md" radius="md" style={{ textAlign: 'center' }}>
          {item.attachment.map((file, index) => (
            <Anchor key={index} href={pb.files.getUrl(item, file)} target="_blank" rel="noopener noreferrer" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px', margin: '15px 0', padding: '10px', color: '#1a73e8', textDecoration: 'none', borderRadius: '4px', fontSize: '16px', lineHeight: '1.5' }}>
              <IconFile size={24} />
              {file}
            </Anchor>
          ))}
        </Paper>
      </Collapse>
    </div>
  );
};

export default AttachmentsList;
