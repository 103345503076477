// src/components/Dashboard/DigestItem.js
import React from "react";
import {
  Card,
  Stack,
  Text,
  Badge,
  Group,
  Flex,
  Button,
  Divider,
} from "@mantine/core";
import { IconClock, IconMessage } from "@tabler/icons-react";
import { format, parseISO, isValid } from "date-fns";
import {
  buttonConfigs,
  getStatusColor,
  responseTypeArabic,
  getPriorityColor,
  getColorForResponse,
  getIconForResponse,
} from "../../utils";
import ResponseButton from "../ResponseButton";
import AttachmentsList from "../AttachmentsList"; // Adjust the import path as necessary
import CommentsList from "../CommentsList"; // Adjust the import path as necessary

const DigestItem = ({ item, handleOpenModal, setUpdateTrigger }) => {
  // Check if DueDate is valid
  const dueDate =
    item.DueDate && isValid(parseISO(item.DueDate))
      ? format(parseISO(item.DueDate), "MM/dd/yyyy")
      : null;

  const itemCountsByResponseType = {
    Approval: 1, // Generate 2 items for Approval Requests
    Informational: 1,
    Assignment: 1,
    Meeting: 1, // Example addition
    Feedback: 1,
    Confirmation: 1, // Example addition
  };

  // Array to store generated items
  const itemCount = itemCountsByResponseType[item.ResponseType] || 1;

  // Array to store generated items
  const generatedItems = [];

  for (let i = 0; i < itemCount; i++) {
    const buttons = buttonConfigs[item.ResponseType] || []; // Get your response button configurations

    generatedItems.push(
      <Flex
      key={`${item.id}-${i}`} // Ensure unique keys
        mih={50}
        gap="sm"
        justify="center"
        align="center"
        direction="row"
        wrap="wrap"
      >
        {/* Render ResponseButtons based on buttons array */}
        {buttons.map((buttonConfig, index) => (
          <ResponseButton
            key={`${item.id}-${index}`} // Ensure unique keys
            style={{ width: "145px" }}
            recordId={item.id}
            responseType={buttonConfig.responseType} // Access responseType from config
            icon={buttonConfig.icon} // Assuming buttonConfigs has an icon property
            color={buttonConfig.color} // Assuming buttonConfigs has a color property
            onUpdate={() => setUpdateTrigger((prev) => prev + 1)}
          >
            {buttonConfig.text}
          </ResponseButton>
        ))}
      </Flex>
    );
  }

  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder>
      <Group justify="space-between" spacing="sm">
        <Badge size="md"
          leftSection={getIconForResponse(item.ResponseType)}
          color={getColorForResponse(item.ResponseType)}
        >
          {responseTypeArabic[item.ResponseType]}
        </Badge>
        
        
        <Badge size="md" color={getStatusColor(item.Status)}>{item.Status}</Badge>
      </Group>
      <Divider
        my="xs"
        variant="dashed"
        labelPosition="center"
        label={
          <>
            
            <Badge variant="outline" color={getPriorityColor(item.Priority)} size="md" fw={700}>
            {item.Priority}
          </Badge>
          </>
        }
      />

      <Stack spacing="sm">
        <Group>
          <Text size="lg" weight={700}>
            {item.Title}
          </Text>
        </Group>
        <Text
          size="sm"
          dangerouslySetInnerHTML={{ __html: item.Description }}
        />
              <Divider my="xs" variant="dotted" />

        {item.RequiredDecision && (
          <Text size="md" color="red" weight={700}>
            القرار المطلوب: {item.RequiredDecision}
          </Text>
        )}

        <Group spacing="sm" justify="center">
          {/* Conditional rendering of DueDate */}
          
          {dueDate && (
            <Text size="sm">
              الموعد النهائي:{" "}
              {item.DueDate
                ? format(parseISO(item.DueDate), "MM/dd/yyyy")
                : "لا يوجد"}
            </Text>
          )}

          
        </Group>
       

        <Flex
          mih={50}
          gap="sm"
          justify="center"
          align="center"
          direction="row"
          wrap="wrap"
        >
          {generatedItems}

          <ResponseButton
            style={{ width: "145px" }}
            recordId={item.id}
            responseType="مراجعة لاحقاً"
            icon={<IconClock size={14} />}
            color="orange"
            onUpdate={() => setUpdateTrigger((prev) => prev + 1)}
          >
            مراجعة لاحقاً
          </ResponseButton>
          <Button
            onClick={() => handleOpenModal(item.id)}
            style={{ width: "145px" }}
            rightSection={<IconMessage size={14} />}
            color="blue"
          >
            تعليق
          </Button>
        </Flex>

        {item.attachment && item.attachment.length > 0 && (
          <AttachmentsList item={item} />
        )}

{item.id && (
  <CommentsList item={item} />
)}
      </Stack>
    </Card>
  );
};

export default DigestItem;
